import { format, formatDistanceToNow, getTime } from 'date-fns';
import { de, enUS, fr } from 'date-fns/locale';

type InputValue = Date | string | number | null;

export function fDate(
  date: InputValue,
  newFormat?: string,
  localeKey?: string
) {
  const fm = newFormat || 'dd MMM yyyy';

  const locales = { enUS, de, fr };

  const selectedLocale = locales[localeKey as keyof typeof locales] || enUS;

  if (!date) return '';

  const formattedDate = format(new Date(date), fm, {
    locale: selectedLocale,
  });

  const germanMonthWithDot = (inputDateString: string) => {
    const monthsWithDot = [
      'Jan',
      'Feb',
      'Mär',
      'Apr',
      'Mai',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Okt',
      'Nov',
      'Dez',
    ];
    let resultDateString = inputDateString;
    monthsWithDot.forEach((month) => {
      const regex = new RegExp(`\\b${month}(\\.|\\b)`, 'g');
      resultDateString = resultDateString.replace(regex, `${month}.`);
    });
    return resultDateString;
  };

  return localeKey === 'de' ? germanMonthWithDot(formattedDate) : formattedDate;
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}
